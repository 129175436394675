import React from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'

import { EmployeeDataT } from '@types'

import { USER_DELETE_MODAL } from '../constants'
import { closeModal, Modal } from '@components'

type Props = {
  data: EmployeeDataT
  onSubmit: () => void
}

export const DeleteUserModal = ({ data, onSubmit }: Props) => {
  return (
    <Modal name={USER_DELETE_MODAL} closeIcon={false} width={500}>
      <div className="grid text-center justify-center">
        <div className="grid gap-5 mb-5">
          <img src="/assets/warning_icon.svg" className="m-auto" />
          <h2 className="font-bold">Delete User</h2>
        </div>

        <div className="grid gap-4">
          <p className="text-text-default">The user below will be deleted.</p>
          <div className="card card-border">
            <div className="grid grid-cols-[0.5fr_2fr] text-left text-sm">
              <p className="font-semibold">User:</p>
              <p className="capitalize">
                {data.name} &nbsp;
                <span className="normal-case">({data.email})</span>
              </p>
            </div>
          </div>
          <div className="rounded-lg border-border-warning-subdued border-2 bg-surface-warning-subdued-default p-[8px_16px] text-left">
            <div className="flex flex-row gap-2 uppercase text-sm font-medium text-text-warning">
              <RiErrorWarningFill size={20} className="text-icon-warning" />
              warning
            </div>
            <p className="text-text-warning font-normal text-xs pt-1">
              User will no longer appear on your platform and all transaction<br/>
              records which related with this user will be permanently deleted.
            </p>
          </div>
          <p className="text-text-subdued">Do you want to delete anyway?</p>
        </div>
        <div className="flex flex-row gap-4 justify-center pt-6">
          <button
            className="btn btn-secondary-solid"
            onClick={() => closeModal([USER_DELETE_MODAL])}
          >
            Cancel
          </button>
          <button
            className="btn btn-destructive-solid"
            onClick={() => onSubmit()}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  )
}
