import React, { useCallback, useMemo } from 'react'
import queryString from 'query-string'
import { withApp } from '@features/layout'
import {
  BreadCrumb,
  Tabs
} from '@components'

import { companySettingsPath, leaveReportsPath } from '@path'
import { TotalReportPage } from './TotalReportPage'

const LeaveReports = () => {
  const link = [
    {
      title: 'Company Settings',
      key: 'company_settings',
      href: companySettingsPath,
    },
    {
      title: 'Leave Reports',
      key: 'leave_reports',
      href: leaveReportsPath,
    },
  ]

  const Tab = [
    {
      title: 'Leave Report',
      key: 'monthly',
      href: leaveReportsPath,
      query: { page: 'monthly' },
    },
  ]

  const query = useMemo(() => {
    let queryParam = queryString.parse(window.location.search)
    if (!queryParam.page) {
      window.location.replace(`${window.location.href}?page=monthly`)
    }
    return queryParam.page
  }, [window.location.search])

  const renderContent = useCallback(() => {
    switch (query) {
      case 'monthly':
        return <TotalReportPage />
      default:
        return
    }
  }, [query])

  return (
    <div className="w-full md:h-screen bg-white flex flex-col">
      <div className="grid sm:grid-flow-col-1 md:grid-cols-[2fr_1fr] justify-between">
        <div>
          <h1 className="font-bold">Leave Report</h1>
          <BreadCrumb data={link} current="leave_reports" />
        </div>
      </div>

      <Tabs tabs={Tab} currentTab={(query as string) ?? 'monthly'} />
      {renderContent()}
    </div>
  )
}
export default withApp(LeaveReports)
