import React, { useEffect, useMemo, useRef, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { HiOutlineCheck } from 'react-icons/hi'

import { OptionT } from '@types'
import { ErrorText } from '../ErrorText'

type Props = {
  onChange?: (value?: OptionT) => void
  options: OptionT[]
  value?: string | number
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
  errorMessage?: string
  errorBg?: string
  position?: 'top' | 'bottom'
}

export const Select = ({
  options,
  onChange,
  value: defaultValue,
  placeholder = 'Select',
  label,
  required,
  errorMessage,
  errorBg,
  position = 'bottom',
}: Props) => {
  const [value, setValue] = useState<OptionT>()
  const ref = useRef<HTMLUListElement>(null)

  useEffect(() => {
    setValue(options.find((item) => item.value === defaultValue))
  }, [defaultValue, options])

  const onChangeValue = (value: OptionT) => {
    setValue(value)
    if (ref.current) {
      ref.current?.blur()
    }
    value && onChange?.(value)
  }

  return (
    <div className="flex flex-col items-baseline w-inherit">
      {label && (
        <p>
          {label} {required && <span className="text-text-critical">*</span>}
        </p>
      )}
      <div
        className={`dropdown dropdown-${position} w-inherit min-w-[150px] bg-white`}
      >
        <label
          className={`btn btn-sm input flex justify-between  !bg-transparent normal-case font-[400] ${
            value ? 'text-text-default' : 'text-text-subdued'
          } ${
            errorMessage
              ? 'border-text-critical'
              : '!border-border-default-subdued'
          }`}
          tabIndex={0}
        >
          {value?.label ?? placeholder}
          <BsChevronDown className="ml-2" />
        </label>
        {errorMessage && <ErrorText text={errorMessage} bg={errorBg} />}
        <ul
          tabIndex={0}
          className="dropdown-content menu p-0 shadow rounded-box w-full mt-2 overflow-auto grid bg-white max-h-52 "
          ref={ref}
        >
          {options.map((option, index) => {
            const isSelected = option.value === value?.value
            return (
              <li onClick={() => onChangeValue(option)} key={index}>
                <a
                  className={`hover:bg-surface-default-subdued ${
                    isSelected ? 'bg-surface-default-subdued' : 'bg-white'
                  }`}
                >
                  {option.label}
                  {isSelected && (
                    <span className="absolute inset-y-0 right-2 flex items-center">
                      <HiOutlineCheck className="text-icon-success w-4 h-4" />
                    </span>
                  )}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
