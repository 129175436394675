import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { z } from 'zod'
import dayjs from 'dayjs'

import { LEAVE_PERIOD_OPTIONS, LEAVE_TYPE_OPTIONS } from '@lib/constants'
import { FULL_DATE_PARAM, formatDateTime, isWeekday } from '@lib/helper'
import { CreateLeaveParam, HolidayData, LeavePeriod, LeaveType } from '@types'

import { LEAVE_EDIT_MODAL } from '../pages/LeavesPage'
import { Calendar, Modal, Select, TextAreaField } from '@components'
import { HolidayServices } from '@lib/services'

type ModalProps = {
  onSubmit: (payload: CreateLeaveParam) => void
  value?: CreateLeaveParam & { duration: string }
  modalName: string
  onClose: (name: string) => void
}

export const LeaveFormModal = ({
  value,
  modalName,
  onClose,
  onSubmit,
}: ModalProps) => {
  const { getHoliday } = HolidayServices()
  const [isEditModal, setIsEditModal] = useState<boolean>(
    modalName === LEAVE_EDIT_MODAL,
  )
  const [holidayData, setHolidayData] = useState<Date[]>([])
  const schema = z.object({
    leave_type: z
      .string({
        required_error: 'leave type is required',
      })
      .min(1),
    starts_date: z.string({
      required_error: 'date is required',
    }),
    ends_date: z.string({
      required_error: 'date is required',
    }),
    period: z
      .string({
        required_error: 'period is required',
      })
      .min(1),
    reason: z.string().nullable(),
  })

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    getValues,
    formState: { errors },
  } = useForm<CreateLeaveParam>({
    resolver: zodResolver(schema),
    defaultValues: value,
  })
  const watchFields = watch([
    'starts_date',
    'ends_date',
    'period',
    'leave_type',
  ])

  const duration = useMemo(() => {
    if (watchFields[0] && watchFields[1]) {
      const start = watchFields[0]
      const end = watchFields[1]
      const dates = []
      let current = start

      while (dayjs(current).isBefore(end) || dayjs(current).isSame(end)) {
        if (isWeekday(new Date(current))) {
          dates.push(current)
        }
        current = dayjs(current).add(1, 'day') as unknown as string
      }

      const count = dates.length

      if (!count) return 0
      switch (watchFields[2]) {
        case 'first_half':
          return count * 0.5
        case 'second_half':
          return count * 0.5
        default:
          return count
      }
    }
    return 0
  }, [watchFields, value?.duration])

  const minDate = useMemo(() => {
    const TYPE = ['business', 'leave_without_pay', 'vacation', 'extra_vacation']
    setValue(
      'starts_date',
      (isEditModal ? value?.starts_date : undefined) as string,
    )
    setValue(
      'ends_date',
      (isEditModal ? value?.ends_date : undefined) as string,
    )
    {
      isEditModal && setIsEditModal(false)
    }
    return TYPE.includes(watchFields?.[3]) ? new Date() : undefined
  }, [watchFields?.[3]])

  const getHolidaysData = async () => {
    const data = await getHoliday()
    setHolidayData(data.holiday_data.map((datum) => datum.date) ?? [])
  }

  useEffect(() => {
    getHolidaysData()
  }, [])

  return (
    <Modal
      name={modalName}
      title={`${value ? 'Edit Request' : 'Create Request'}`}
      onClose={() => onClose(modalName)}
      visible={'overflow-visible'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 mt-2 text-text-default w-full">
          <Select
            label="Leave Type"
            value={getValues('leave_type')}
            errorMessage={errors.leave_type?.message}
            options={LEAVE_TYPE_OPTIONS.filter((item) => item.value)}
            onChange={(data) => {
              setValue('leave_type', data?.value as LeaveType)
            }}
            required={true}
          />
          <div className="grid">
            <div className="grid grid-cols-2 justify-between">
              <p>
                Leave Date <span className="text-text-critical">*</span>
              </p>
              <p className="text-text-subdued text-end  sm:text-xs md:text-sm">
                Leave Summary: {duration} days
              </p>
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 w-full gap-4 bg-background-default p-1 rounded">
              <Calendar
                excludeDates={holidayData}
                date={
                  getValues('starts_date')
                    ? [
                        new Date(getValues('starts_date') as string),
                        getValues('ends_date')
                          ? new Date(getValues('ends_date') as string)
                          : null,
                      ]
                    : undefined
                }
                minDate={minDate}
                onChange={(value) => {
                  setValue(
                    'starts_date',
                    formatDateTime(
                      value?.[0] as unknown as string,
                      FULL_DATE_PARAM,
                    ) as string,
                  )
                  setValue(
                    'ends_date',
                    formatDateTime(
                      value?.[1] as unknown as string,
                      FULL_DATE_PARAM,
                    ) as string,
                  )
                }}
                errorMessage={
                  errors.starts_date?.message || errors.ends_date?.message
                }
              />
              <Select
                value={value?.period}
                options={LEAVE_PERIOD_OPTIONS}
                onChange={(value) =>
                  setValue('period', value?.value as LeavePeriod)
                }
                errorMessage={errors.period?.message}
                errorBg="background-default"
              />
            </div>
          </div>
          <TextAreaField
            name="reason"
            register={register}
            label="Reason"
            placeholder="Enter Reason"
          />

          <div className="w-full flex flex-row space-x-4 mt-2">
            <label
              className="btn w-1/2 font-semibold bg-action-critical-default border-none hover:bg-action-critical-hovered"
              onClick={() => onClose(modalName)}
            >
              Cancel
            </label>

            <button
              type="submit"
              className="btn w-1/2 font-semibold bg-action-primary-default border-none hover:bg-action-primary-hovered"
            >
              {value ? 'Update' : 'Create'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
