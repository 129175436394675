import React, { useEffect, useState } from 'react'
import { ExportIcon, Table, Pagination, Status } from '@components'
import {
  formatDateTime,
  formatDateWithPeriod,
  formatDisplayDate,
  renderLeaveName,
} from '@lib/helper'
import { formatTeamOptions } from '.'
import { userManagementServices } from '../../user_management/services'
import { leaveBalanceServices } from '../services'
import {
  LeaveReportResponse,
  LeaveReportParam,
  ColumnT,
  OptionT,
} from '@types'
import { SearchReport } from '../components/SearchReport'

export const TotalReportPage = () => {
  const { getTeams } = userManagementServices()
  const { getMonthlyReport } = leaveBalanceServices()
  const [leaveReport, setLeaveReports] = useState<LeaveReportResponse>()
  const [search, setSearch] = useState<LeaveReportParam>()
  const [teamOptions, setTeamOptions] = useState<OptionT[]>()

  const extendSearch = {...search, page: 1, per: -1}
  const filteredSearch = Object.fromEntries(
    Object.entries(extendSearch).filter(([_, value]) => value !== undefined)
  );
  const queryString = new URLSearchParams(filteredSearch as any).toString();

  const columns: ColumnT[] = [
    {
      label: 'Employee Name',
      key: 'employee_name',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[15%]',
    },
    {
      label: 'Employee Email',
      key: 'employee_email',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[15%]',
    },
    {
      label: 'Status',
      key: 'employee_status',
      width: 'sm:min-w-[80px] md:w-[5%] 2xl:w-[5%]',
      render: (row) => <Status status={row.employee_status} type="employee" />,
    },
    {
      label: 'Team',
      key: 'team',
      width: 'sm:min-w-[100px] md:w-[10%] 2xl:w-[10%]',
    },
    {
      label: 'Leave Type',
      key: 'leave_type',
      width: 'sm:min-w-[100px] md:w-[10%] 2xl:w-[10%]',
      render: (row) => <>{renderLeaveName(row.leave_type)}</>,
    },
    {
      label: 'Submitted Date',
      key: 'submitted_date',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[15%]',
      render: (row) => <> {formatDateTime(row.submitted_date)}</>,
    },
    {
      label: 'Leave Date',
      key: 'datetime_range',
      width: 'sm:min-w-[120px] md:w-[20%] 2xl:w-[20%]',
      render: (row) => (
        <>
          {formatDateWithPeriod(
            formatDisplayDate(
              row.starts_date,
              row.ends_date,
              row.datetime_range,
            ),
            row.period,
          )}
        </>
      ),
    },
    {
      label: 'Days',
      key: 'days',
      width: 'sm:min-w-[80px] md:w-[5%] 2xl:w-[5%]',
    },
  ]

  const getTeamsOptions = async () => {
    const res = await getTeams()
    const format = formatTeamOptions(res.departments)
    setTeamOptions(format as OptionT[])
  }

  const getLeaveReportApi = async () => {
    const response = await getMonthlyReport(search)
    setLeaveReports(response)
  }

  useEffect(() => {
    getTeamsOptions()
  }, [])

  useEffect(() => {
    getLeaveReportApi()
  }, [search])

  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-[2fr_1fr] items-center">
        <SearchReport
          onChange={(filter) => setSearch({ ...filter, page: 1 })}
          totals={leaveReport?.meta.total_count ?? 0}
          searchReport={search}
          teamOptions={teamOptions ?? []}
        />
        <div className="flex sm:flex-col md:flex-row gap-[8px] justify-end">
          <a
            href={`/admin/company_settings/leave_reports/export.csv?${queryString}`}
            className="btn btn-primary-outline btn-sm sm:mb-4 md:mb-0"
          >
            <ExportIcon className="fill-icon-primary mr-1" />
            &nbsp;Export
          </a>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table columns={columns} data={leaveReport?.reports ?? []} />
      </div>
      <div className="grid justify-end pt-4">
      <Pagination
          total_pages={leaveReport?.meta.total_pages}
          per_page={leaveReport?.meta.per}
          page={leaveReport?.meta.page}
          onPageChange={(page) => setSearch({ ...search, page: page })}
          onPerPageChange={(value) =>
            setSearch({ ...search, per: value, page: 1 })
          }
        />
      </div>
    </>
  )
}
