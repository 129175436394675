import React, { useEffect, useState, useRef } from 'react'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { z } from 'zod'

import { EMPLOYEE_ROLE_OPTIONS } from '@lib/constants'
import { CreateEmployeeParam, OptionT } from '@types'

import { userManagementServices } from '../services'
import { ManagerField } from './ManagerField'
import { Input, Modal, Select, Toggle } from '@components'

const MAX_MANAGERS = 2;
const ERROR_TIMEOUT = 3000;

type Props = {
  onSubmit: (payload: CreateEmployeeParam) => void
  modalName: string
  value?: CreateEmployeeParam
  onClose: (name: string) => void
}
export const UserFormModal = ({
  value,
  modalName,
  onClose,
  onSubmit,
}: Props) => {
  const { getTeams, getDirectManager, getAdminManager } = userManagementServices()
  const [teamOptions, setTeamOptions] = useState<OptionT[]>([])
  const [directManagerOptions, setDirectManagerOptions] = useState<OptionT[]>(
    [],
  )
  const [adminOptions, setAdminOptions] = useState<OptionT[]>(
    [],
  )
  const mergedOptions = [...directManagerOptions, ...adminOptions];
  const [managerError, setManagerError] = useState('');

  const managerSchema = z.object({
    manager_id: z.number({
      required_error: 'manager is required',
    }),
    role: z.string({
      required_error: 'role is required',
    }),
    manager_order: z.number().int(),
  });

  const schema = z.object({
    email: z.string().min(1, 'email is required'),
    first_name: z.string().min(1, 'first name is required'),
    last_name: z.string().min(1, 'last name is required'),
    department_id: z.number({
      required_error: 'team is required',
    }),
    role: z.string({
      required_error: 'role is required',
    }),
    direct_manager_id: z.number({
      required_error: 'direct manager is required',
    }),
    status: z.string().optional(),
    employee_managers: z
      .array(managerSchema)
      .refine(
        (managers) =>
          new Set(managers.map((manager) => manager.manager_id)).size === managers.length,
        {
          message: 'Duplicate manager is not allowed.',
        }
      ),
  })

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    setError,
    formState: { errors },
  } = useForm<CreateEmployeeParam>({
    resolver: zodResolver(schema),
    defaultValues: value ? value : {
      status: 'inactive',
      employee_managers: []
    },
  })

  const { fields: managerFields, append, remove } = useFieldArray({
    control,
    name: 'employee_managers',
  });

  const watchFields = watch(['department_id', 'status'])

  const getTeamsOptions = async () => {
    const res = await getTeams()
    const format: OptionT[] = res?.departments.map((item) => {
      return { label: item.name, value: item.id }
    })
    setTeamOptions(format)
  }

  const getAdminManagerOptions = async () => {
    const res = await getAdminManager({role: 'admin'})
    setAdminOptions(res)
  }

  const errorTimeoutRef = useRef<number | null>(null);

  const getDirectManagerOptions = async (id: number) => {
    const res = await getDirectManager({ department_id: id })
    setDirectManagerOptions(res)
  }

  const employeeManagers = useWatch({
    control,
    name: 'employee_managers',
  });

  useEffect(() => {
    const seenIds = new Set();

    employeeManagers.forEach((manager, index) => {
      if(seenIds.has(manager.manager_id)) {
        setError(`employee_managers.${index}.manager_id`, {
          type: 'manual',
          message: 'Duplicate manager is not allowed',
        });
      } else {
        setError(`employee_managers.${index}.manager_id`, {
          type: 'manual',
          message: '',
        });
        seenIds.add(manager.manager_id);
      }
    });
  }, [employeeManagers, setError]);

  const handleAddManager = () => {
    if (managerFields.length < MAX_MANAGERS) {
      append({ id: undefined, role: "approve", manager_id: undefined , employee_id: undefined });
    } else {
      setManagerError(`You can only add up to ${MAX_MANAGERS} managers.`);

      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = window.setTimeout(() => {
        setManagerError('');
      }, ERROR_TIMEOUT);
    }
  };

  useEffect(() => {
    if (managerFields.length <= MAX_MANAGERS) {
      setManagerError('');
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    }
  }, [managerFields.length]);

  useEffect(() => {
    if (watchFields[0]) {
      getDirectManagerOptions(watchFields[0])
    }
    setDirectManagerOptions([])
  }, [watchFields[0]])

  useEffect(() => {
    getTeamsOptions()
    getAdminManagerOptions()
  }, [])

  useEffect(() => {
    managerFields.forEach((field, index) => {
      // Set manager_order based on current index
      setValue(`employee_managers.${index}.manager_order`, index + 1);
    });
  }, [managerFields, setValue]);

  return (
    <Modal
      name={modalName}
      title={`${value ? 'Edit User' : 'Create User'}`}
      onClose={() => onClose(modalName)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 w-full">
          <Input
            placeholder="Email"
            label="Email"
            required
            name="email"
            register={register}
            errorMessage={errors.email?.message}
          />
          <div className="flex sm:flex-wrap md:flex-nowrap sm:flex-col md:flex-row gap-4 w-full">
            <Input
              placeholder="First Name"
              label="First Name"
              name="first_name"
              required
              register={register}
              errorMessage={errors.first_name?.message}
            />
            <Input
              placeholder="Last Name"
              label="Last Name"
              name="last_name"
              required
              register={register}
              errorMessage={errors.last_name?.message}
            />
          </div>

          <Select
            label="Team"
            required
            placeholder="Select Team"
            value={value?.department_id}
            options={teamOptions}
            onChange={(value) =>
              setValue('department_id', value?.value as number)
            }
            errorMessage={errors.department_id?.message}
          />
          <Select
            label="Role"
            required
            value={value?.role}
            placeholder="Select Role"
            options={EMPLOYEE_ROLE_OPTIONS}
            onChange={(value) => setValue('role', value?.value as string)}
            errorMessage={errors.role?.message}
          />
          <Select
            label="Direct Manager"
            required
            value={value?.direct_manager_id}
            placeholder="Select Direct Manager"
            options={directManagerOptions}
            onChange={(value) =>
              setValue('direct_manager_id', value?.value as number)
            }
            errorMessage={errors.direct_manager_id?.message}
          />
          <div>
            <div className="grid grid-cols-3 justify-items-stretch">
              <div className="col-span-2">
                <label>Additional Managers</label>
                {managerError && <p className="text-red-500">{managerError}</p>}
                {errors.employee_managers && <p className="text-red-500">{errors.employee_managers?.message}</p>}
              </div>
              <button
                type="button"
                className="btn btn-xs btn-primary-outline capitalize justify-self-end "
                onClick={handleAddManager}
              >
                + Add manager
              </button>
            </div>
            {managerFields.map((item, index) => (
              <div key={item.id}>
                <ManagerField
                  control={control}
                  setValue={setValue}
                  index={index}
                  managerOptions={mergedOptions}
                  onRemove={remove}
                  errors={errors.employee_managers?.[index]}
                />
              </div>
            ))}
          </div>
          <Toggle
            isChecked={watchFields[1] === 'active'}
            description="Active"
            label="Status"
            name="status"
            onChange={(value) =>
              setValue('status', value ? 'active' : 'inactive')
            }
          />
        </div>

        <div className="w-full flex flex-row justify-center space-x-4 mt-[32px]">
          <button
            type="button"
            className="btn btn-primary-outline capitalize"
            onClick={() => onClose(modalName)}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary-solid">
            {value ? 'Save Changes' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  )
}
