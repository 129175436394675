import { AxiosError } from 'axios'
import { concat } from 'lodash'

import { axios, getErrorMessage, axiosFormData } from '@lib/helper'
import {
  adminLeaveBalancesApi,
  adminLeaveBalancesEmployeeQuotaApi,
  adminLeaveBalancesAdjustQuotaApi,
  adminLeaveBalancesBulkUpdateQuotaApi,
  adminTotalLeaveReportsApi
} from '@path'
import { LeaveBalancesResponse, EmployeeQuotaResponse, LeaveReportResponse } from '@types'
import { showToast } from '@components'

export const leaveBalanceServices = () => {
  return {
    getLeaveBalances: async (payload?: {}): Promise<LeaveBalancesResponse> => {
      const res = await axios.get(adminLeaveBalancesApi, {params: payload})
      return res.data || {}
    },
    getEmployeeQuota: async (payload?: { employee_id: number }):
    Promise<EmployeeQuotaResponse> => {
      const res = await axios.get(adminLeaveBalancesEmployeeQuotaApi, { params: payload })
      return res.data || {}
    },
    adjustQuota: async (payload: any) => {
      try {
        await axios.patch(adminLeaveBalancesAdjustQuotaApi, payload)
        showToast({ type: 'success', message: 'Update User Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    bulkUpdateQuota: async (payload: any) => {
      try {
        await axiosFormData.patch(adminLeaveBalancesBulkUpdateQuotaApi, payload)
        showToast({ type: 'success', message: 'Update Quota Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    getMonthlyReport: async (payload?: {}): Promise<LeaveReportResponse> => {
      const res = await axios.get(adminTotalLeaveReportsApi, {params: payload})
      return res.data || {}
    },
  }
}
