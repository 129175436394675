import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { z } from 'zod'

import {
  EmployeeQuotaResponse,
  EmployeeLeaveTypeDataT,
  LeaveBalancesDataT,
  ColumnT,
} from '@types'

import { leaveBalanceServices } from '../services'
import { Modal, Table } from '@components'
import { QuotaInput } from '../components/QuotaInput'

type Props = {
  onSubmit: (payloads: EmployeeLeaveTypeDataT[]) => void
  modalName: string
  value?: LeaveBalancesDataT
  onClose: (name: string) => void
}
export const EmployeeLeaveTypesFormModal = ({
  value,
  modalName,
  onClose,
  onSubmit,
}: Props) => {
  const schema = z.object({
    quota: z.number().optional()
  })

  const {
    handleSubmit,
  } = useForm({
    resolver: zodResolver(schema),
  })

  const {
    getEmployeeQuota
  } = leaveBalanceServices()
  const [employeeQuota, setEmployeeQuota] = useState<EmployeeQuotaResponse>()
  const [formValues, setFormValues] = useState<EmployeeLeaveTypeDataT[]>()

  const columns: ColumnT[] = [
    {
      label: 'Leave Types',
      key: 'leave_type_name',
      width: 'sm:min-w-[100px] md:w-[20%] 2xl:w-[20%]',
    },
    {
      label: 'Current',
      key: 'quota',
      width: 'sm:min-w-[10px] md:w-[15%] 2xl:w-[15%]',
    },
    {
      label: 'Adjust',
      key: 'adjust_quota',
      width: 'sm:min-w-[110px] md:w-[25%] 2xl:w-[25%]',
      render: (row) =>
        <QuotaInput
          value={row}
          updateFormValues={updateQuota}
        />
    }
  ]

  const updateQuota = (name:string, value:number) => {
    const newState = formValues?.map(item => {
      if (item.leave_type_name === name) {
        return { ...item, quota: value };
      }
      return item;
    });

    setFormValues(newState);
  }

  const getEmployeeQuotaApi = async () => {
    if (value) {
      const res = await getEmployeeQuota({ employee_id: value?.id })
      setEmployeeQuota(res)
      setFormValues(res?.quota)
    }
  }

  useEffect(() => {
    getEmployeeQuotaApi()
  }, [])

  return (
    <Modal
      name={modalName}
      title={'Adjust Quota'}
      onClose={() => onClose(modalName)}
    >
      <div>
        <h4 className="font-semibold text-text-default">
          Employee
        </h4>
        <div
          className="card grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1
          justify-items-left rounded-2xl bg-background-default p-4 gap-4 mt-[24px] cursor-pointer"
        >
          {employeeQuota?.employee.name}
        </div>
      </div>
      <br/>
      <form onSubmit={handleSubmit(() => onSubmit(formValues))}>
        <div>
          <h4 className="font-semibold text-text-default">
            Leave Quota Adjust
          </h4>
          <br/>
        </div>
        <div className="overflow-auto">
          <Table columns={columns} data={employeeQuota?.quota ?? []} />
        </div>
        <div className="w-full flex flex-row justify-end space-x-4 mt-[32px]">
          <button
            type="button"
            className="btn btn-primary-outline capitalize"
            onClick={() => onClose(modalName)}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary-solid">
            {value ? 'Save Changes' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  )
}
