import dayjs from 'dayjs'
import { DateRangeValue } from '@types'
import { FORMAT_DATE } from '@lib/helper'

export function getTodayValues(): DateRangeValue {
  return [
    new Date(dayjs().format(FORMAT_DATE)),
    new Date(dayjs().format(FORMAT_DATE)),
  ]
}

export function getYesterdayValues(): DateRangeValue {
  return [
    new Date(dayjs().subtract(1, 'day').format(FORMAT_DATE)),
    new Date(dayjs().subtract(1, 'day').format(FORMAT_DATE)),
  ]
}

export function getThisWeekValues(): DateRangeValue {
  return [
    new Date(dayjs().day(1).format(FORMAT_DATE)),
    new Date(dayjs().day(5).format(FORMAT_DATE)),
  ]
}

export function getThisMonthValues(): DateRangeValue {
  return [
    new Date(dayjs().startOf('month').format(FORMAT_DATE)),
    new Date(dayjs().endOf('month').format(FORMAT_DATE)),
  ]
}

export function getThisYearValues(): DateRangeValue {
  return [
    new Date(dayjs().startOf('year').format(FORMAT_DATE)),
    new Date(dayjs().endOf('year').format(FORMAT_DATE)),
  ]
}
