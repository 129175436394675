import React from 'react'
import { Input } from '@components'
import {
  LeaveBalancesParam,
} from '@types'

type Props = {
  onChange: (filter?: LeaveBalancesParam) => void
  total: number
  search?: LeaveBalancesParam
}
export const SearchEmployee = ({ onChange, total, search }: Props) => {
  return (
    <div className="grid w-full sm:grid-cols-1 md:grid-cols-[220px_220px_220px_auto] gap-2 my-4 md:items-center sm:items-baseline">
      <Input
        name="query"
        value={search?.query}
        placeholder={'Search'}
        onChange={(value) =>
          onChange({ ...search, query: value as string})
        }
      />
      <div className="font-bold text-sm">
        Matching: <span className="font-normal">{total} items</span>
      </div>
    </div>
  )
}
