import React from 'react'
import { Calendar, Select, Input } from '@components'
import { OptionT, LeaveReportParam } from '@types'
import { formatDateTime, FULL_DATE_PARAM } from '@lib/helper'
import { LEAVE_TYPE_OPTIONS } from '@lib/constants'

type Props = {
  onChange: (filter: LeaveReportParam) => void
  totals: number
  teamOptions: OptionT[]
  searchReport?: LeaveReportParam
}
export const SearchReport = ({ onChange, totals, teamOptions,searchReport }: Props) => {
  return (
    <div className="flex flex-wrap sm:w-full md:w-auto sm:flex-col md:flex-row gap-2 my-4 md:items-center sm:items-baseline">
      <Input
        name="query"
        value={searchReport?.search}
        placeholder={'Search'}
        onChange={(value) =>
          onChange({ ...searchReport, search: value as string})
        }
      />
      <Select
        onChange={(value) => {
          onChange({ ...searchReport, department_id: value?.value as number })
        }}
        options={teamOptions ?? []}
        value={searchReport?.department_id}
      />
      <Select
        onChange={(value) =>
          onChange({ ...searchReport, leave_type: value?.value as string})
        }
        options={LEAVE_TYPE_OPTIONS}
        value={searchReport?.leave_type}
      />
      <Calendar
        placeholder="All Date Time Range"
        onChange={(value) =>
          onChange({
            ...searchReport,
            starts_date: formatDateTime(
              value?.[0] as unknown as string,
              FULL_DATE_PARAM,
            ),
            ends_date: formatDateTime(
              value?.[1] as unknown as string,
              FULL_DATE_PARAM,
            )
          })
        }
      />
      <div className="font-bold text-sm">
        Matching: <span className="font-normal">{totals} items</span>
      </div>
    </div>
  )
}
